import React from 'react';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import classnames from 'classnames';

import TheHeader from '../components/common/TheHeader';
import ConnectWallet from '../components/auth/ConnectWallet';

import styles from '../sass/pages/NotFoundPage.module.scss';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    wizardStep: store.data.wizardStep,
  };
};

class NotFoundPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  goToHomepage = () => {
    this.props.router.push('/');
  }

  redirectToWizard = (user) => {
    const url = this.props.wizardStep || user.username;
    this.props.router.push(url);
  }

  render = () => {
    return (
      <div
        className={styles.notFoundPage}
      >
        <TheHeader />
        <div className={classnames('container', styles.notFoundContainer)}>
          <h1>Profile not found.{!this.props.user && ' Claim it now while it\'s free!'}</h1>
          {!this.props.user && <ConnectWallet onConnect={this.redirectToWizard} />}
        </div>
      </div>
    );
  }

}

export default connect(mapStoreToProps)(withRouter(NotFoundPage));
